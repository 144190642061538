import React, { useState, useEffect } from 'react'
import { getBalances } from '../../../services/HydraService'
import { i18n } from '../../../utils/translates/i18n'
import LoaderPage from '../../../utils/LoaderPage'
import { toast } from 'react-toastify'

export default function Balances({ rule }) {
  const [report, setReport] = useState({})
  const [show, setShow] = useState(false)

  useEffect(() => {
    getBalances()
      .then(result => {
        setReport(result)
        setShow(true)
      })
      .catch(error => {
        console.error(error.response ? error.response.data : error)
        toast.error(error.response ? error.response.data : error)
        setShow(true)
      })
  }, [])

  return (
    <div className="col-md-4 col-sm-6 mb-4">
      <div className="card border-0 shadow h-100">
        <div className="card-body">
          {!show ? (
            <LoaderPage />
          ) : (
            <div className="row d-block d-xl-flex align-items-center">
              {report?.balances &&
                Object.keys(report.balances).length > 0 &&
                Object.entries(report.balances).map(
                  (b, ib) =>
                    (b[1].amount > 0 || b[1].pending > 0) && (
                      <div className="col-12 d-flex" key={ib}>
                        <div className="icon-shape icon-shape-info rounded me-4 me-sm-0">
                          <img
                            src={`https://www.coinpayments.net/images/coins/${b[0]
                              .split('.')[0]
                              .split('matic')[0]
                              ?.toUpperCase()}.png`}
                            alt={b[0].split('.')[0].toUpperCase()}
                            className="icon"
                          />
                        </div>
                        <div className="ms-3">
                          <h2 className="h5">{`${i18n.t('balance')} ${b[0].split('.')[0]}`}</h2>
                          <h5 className="fw-extrabold">
                            <small>{i18n.t('amount')}: </small>
                            {['manager'].includes(rule) ? (parseFloat(b[1].amount) / 100) * 50 : b[1].amount}
                          </h5>
                          <h5 className="fw-extrabold">
                            <small>{i18n.t('pendent')}: </small>
                            {b[1].pendingAmount}
                          </h5>
                        </div>
                      </div>
                    )
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
